import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const MainV2Marketplace = () => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isHovered, setIsHovered] = useState(null);

  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  return (
    <div
      ref={sectionRef}
      className="relative flex flex-col w-full h-full max-w-[1920px] max-h-[1080px] mb-20 lg:mb-24 bg-[#000000] pt-[4rem] lg:pt-[1rem]"
    >

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="w-full mt-[-2rem] z-[1000]" style={{ transform: "rotate(-0.5deg)" }}>
        {!isMobile ? (
          <video
            src="https://storage.animara.world/warning-bar.webm"
            className="w-full h-full z-200 scale-[1]"
            autoPlay
            loop
            muted
            playsInline
          />
        ) : (
          <img
            src="/assets/mainV2/warning.png"
            className="w-full h-full scale-[2.5] lg:pt-10"
            alt="Event Background"
          />
        )}
      </div>

      <div className="h-full flex flex-col md:mt-[-1rem] lg:mt-[-2rem] justify-center items-center overflow-hidden">
        <div className="flex flex-row justify-center items-center w-full h-auto z-[100] lg:z-0 scale-105">
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              key={`container-${index}`}
              className="relative min-w-[140px] lg:min-w-[200px] max-w-[25%] h-[300px] lg:h-[480px] inset-0 z-10 mx-[-0.5rem]"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <img
                key={`marketplace-${index}-main`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                className={`absolute inset-0 w-full h-full transition-all duration-300 overflow-clip ${isHovered === index ? 'opacity-0' : 'opacity-100'}`}
                src={`/assets/mainV2/marketplace/default/main-character-${index}.webp`}
                alt={`Character ${index}`}
              />
              <img
                key={`marketplace-${index}-hover`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                className={`absolute inset-0 w-full h-full transition-all duration-300 overflow-clip ${isHovered === index ? 'opacity-100' : 'opacity-0'}`}
                src={`/assets/mainV2/marketplace/hovered/main-character-${index}.webp`}
                alt={`Character ${index}`}
              />
            </div>
          ))}
        </div>


        <div className="w-full py-[3rem] flex justify-center items-center mt-[2rem] lg:mt-0 z-96">
          <div className="w-full flex flex-col items-center justify-center">
            <h1
              className={`text-center text-[#FFC85A] text-[15vw] lg:text-9xl font-normal font-bigNoodle transition-opacity duration-1000 -mt-12 lg:mt-4 lg:px-0`}
            >
              {t("main marketplace title")}
            </h1>
            <p
              className={`text-center text-white text-[3.5vw] lg:text-lg xl:text-xl font-medium font-outfit transition-opacity duration-1000 pb-4 lg:pb-8 mt-0 lg:mt-[1rem]`}
            >
              {t("main marketplace description1")}
            </p>
            <a
              type="button"
              href="https://app.animara.world"
              className="px-[4rem] py-[1rem] bg-[#FFB23F] drop-shadow-lg text-white text-center rounded-full text-base lg:text-lg xl:text-xl font-outfit font-bold mt-2 lg:mt-4 transition-all duration-300 hover:scale-105"
            >
              {t("mint now")}
            </a>
          </div>
        </div>

      </div>
    </div>
  );
};

export default MainV2Marketplace;
